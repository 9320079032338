<template>
  <div class="min-h-screen text-red-900 flex justify-center dlbox" style="height: 100vh; position: relative">
    <div class="max-w-screen-xl m-0 sm:m-24 sm:rounded-lg flex justify-center flex-1"
      style="background-color: transparent">
      <div class="flex-1 text-center hidden lg:flex">
        <div>
          <div style="
              color: #fff;
              font-size: 23px;
              margin-top: 10vh;
              margin-left: 10vw;
              text-align: left;
            ">
            <h1 style="
                font-size: 60px;
                font-weight: 600;
                margin-bottom: 5vh;
                border-bottom: 5px solid #fff;
                letter-spacing: 10px;
              ">
              建<span style="font-size: 80px">筑</span><span style="color: #d1100b">+</span>共<span
                style="font-size: 80px">赢</span><span style="color: #d1100b">+</span>智慧云平<span
                style="font-size: 80px">台</span>
            </h1>
            <div style="letter-spacing: 5px">
              <h5 style="margin-bottom: 1vh">
                这里将呈现给<span style="font-size: 40px">您</span>的是：
              </h5>
              <ul style="margin-left: 20px">
                <li class="liNum">项目与任务的管理</li>
                <li class="liNum">场景化应用的生态工具箱</li>
                <li class="liNum">快速便捷的数据查询与统计分析</li>
                <li class="liNum">工作中的协同</li>
                <li class="liNum">云存储</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="eltabs" class="lg:w-1/2 xl:w-3/12" style="margin: 180px 80px 0 0">
      <div style="border-radius: 15px; overflow: hidden">
        <el-card v-if="ShopId">
          <div slot="header" style="text-align: center;font-size: 20px;color: #D1100B;letter-spacing:2px">
            <i style="cursor: pointer; width: 25px; height: 25px" class="el-icon-s-custom"></i>
            <span style="margin-left: 5px; line-height: 40px">手机登录</span>
          </div>

          <div class=" flex flex-col items-center">
            <div style="width: 100%">
              <div v-loading="loading" class="loginAndRegister">
                <div class="box1">
                  <div id="login">
                    <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                      <h2 style="
                            margin: 0;
                            font-size: 24px;
                            letter-spacing: 2px;
                            font-weight: bold;
                          ">
                        商铺报审登录
                      </h2>
                    </div>
                    <el-form label-position="top" label-width="80px" :model="loginInfo">
                      <el-form-item>
                        <el-input v-model="loginInfo.phone" type="number" placeholder="请输入手机号"
                          prefix-icon="el-icon-mobile-phone">
                          <span @click="sendCode" :disabled="isSending || countdown > 0" slot="suffix"
                            style="color: #D1100B;cursor: pointer;" v-if="isuser">
                            {{ isSending ? '获取中...' : countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
                          </span>
                        </el-input>
                      </el-form-item>
                      <el-form-item v-if="isuser">
                        <el-input v-model.number="loginInfo.SmsCode" type="number" placeholder="请输入验证码"
                          prefix-icon="el-icon-tickets"></el-input>
                      </el-form-item>



                      <el-form-item prop="TiaoKuanRegister">
                        <p>
                          <el-checkbox v-show="Codedisabled" v-model="checked" class="tiaokuan">我已阅读并同意
                            <router-link :to="{ path: '/NoT/Article' }" style="color: #d1100b; text-decoration: none"
                              target="_blank">
                              《服务协议》
                            </router-link>
                            <router-link :to="{ path: '/NoT/Privacys' }" style="color: #d1100b; text-decoration: none"
                              target="_blank">
                              《隐私政策指引》
                            </router-link>
                          </el-checkbox>
                        </p>
                      </el-form-item>
                      <el-form-item>
                        <el-button style="  width: 100%;
                              justify-content: center;
                              font-size: 20px;
                              font-weight: 600;
                              letter-spacing: 20px;
                              height: 40px;
                              padding: 0;
                              border-radius: 20px;" type="primary" @click="login">登录</el-button>
                      </el-form-item>
                    </el-form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card v-else>
          <div style="height: 300px;text-align: center;line-height: 300px;font-size: 20px;color: #D1100B;">
            当前页面链接错误，请联系顾问单位
          </div>
        </el-card>
        <!-- <el-tabs type="border-card" :stretch="true">
          <el-tab-pane>
           
          </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
    <div style="
        position: absolute;
        top: 0;
        width: 100vw;
        background: #fff;
        height: 10vh;
      "></div>
    <div class="Newlogo" style="
        width: 12vw;
        height: 10vh;
        padding: 0 0px;
        background-color: #fff;
        position: absolute;
        left: 10vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
      ">
      <img style="height: 60%" src="../../../assets/筑赢台logo/正常.png" alt="" />
      <img style="height: 40%; margin-left: -5px" src="../../../assets/筑赢台logo/zytlogozi.jpg" alt="" />
    </div>

    <div class="footer">
      <p>
        <span style="font-weight: bold">ICP备案号：</span><span style="margin-right: 20px">京ICP备2021033771号</span>
        <span style="font-weight: bold">技术支持<i class="el-icon-phone"
            style="color: red;"></i>：</span><span>13120268450</span>
      </p>
      <p>
        <span style="font-weight: bold">Copyright &copy;</span><span style="margin-right: 20px">2021-{{ new
          Date().getFullYear() }}</span>
        <span style="font-weight: bold">版权所有：</span><span>铂诺客智能科技（北京）有限公司</span>
      </p>
    </div>
  </div>
</template>

<script>
import TokenCache from "@/utils/cache/TokenCache";
import { mapState } from "vuex";

import "@/assets/Index/index.css";

let checkLoginPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
let checkRegisterPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
export default {
  name: "Index",
  props: ["centerDialogVisibleSecond"],
  components: {

  },
  data() {
    // 登录
    const validatecip = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm1.checkPass !== "") {
          this.$refs.ruleForm1.validateField("checkPass");
        }
        callback();
      }
    };

    // 注册
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      isuser: true,
      loginInfo: {
        checked: false,
      },
      isSending: false,
      countdown: 0,
      iskeyboard: false,
      showLoad: false,
      codeValue: document.location.origin + "/XunJianGo",
      codeValueIOS: document.location.origin + "/XunJianGoIOS",
      imageUrl: require("@/assets/XunJianGo_Logo.png"), //icon路径

      loginAndRegisterShow: false,
      showPrise: true,
      showRentPrise: true,
      NoToken: true, //无Token
      ruleForm1: {
        Number: "",
        Cipher: "",
      },
      rules1: {
        Number: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkLoginPhone,
            trigger: "blur",
          },
        ],
        Cipher: [
          {
            validator: validatecip,
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      ruleForm2: {
        smscode: "",
        tel: "",
        word: "",
        pass: "",
        checkPass: "",
      },
      rules2: {
        smscode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkRegisterPhone,
            trigger: "blur",
          },
        ],
        word: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      dialogVisible: false,
      dialogVisibleDownload: false,
      timer: null,
      oneMinute: 60,
      flag: false, // 密码可视
      resetflag: false, // 密码可视
      checked: true,
      disabled: true,
      Codedisabled: true,
      buttonText: "",
      isDisabled: false,
      key: "",
      url: "",
      appid: "wxef625270eda5492e",
      scope: "snsapi_login",
      codeData: {},
      unionid: null,
      ShopId: "",
      verify: false,
    };
  },
  methods: {
    login() {
      // console.log(this.checked)

      if (!this.isValidPhoneNumber(this.loginInfo.phone)) {

        this.$message.warning("请输入正确的手机号");
        return;
      }
      let url = ''
      let obj = {
        "Phone": "",
        "SmsCode": "",
        "Password": ""
      }
      if (this.isuser) {

        if (!this.loginInfo.SmsCode) {

          this.$message.warning("请输入验证码");
          return;
        }
        url = '/Base_ProjectManage/Project_ExternalUser/EUSubmitLoginBySMSCode'
        obj.Phone = this.loginInfo.phone
        obj.SmsCode = this.loginInfo.SmsCode
        obj.Password = ''

      } else {
        if (!this.loginInfo.Password) {

          this.$message.warning("请输入密码");
          return;
        }
        url = '/Base_ProjectManage/Project_ExternalUser/EUSubmitLoginByPassword'
        obj.Phone = this.loginInfo.phone
        obj.SmsCode = ''
        obj.Password = this.loginInfo.Password
      }
      if (!this.checked) {

        this.$message.warning("请勾选服务协议和隐私政策");
        return;
      }
      this.loading = true;
      this.$http.post(url, obj).then(async res => {
        this.loading = false;
        if (res.Success) {
          if (res.Data.EUInfo) {
            window.localStorage.setItem('ApprovalUnitUserInfo', JSON.stringify(res.Data.EUInfo))
          }

          TokenCache.setToken(res.Data.Token);
          // await this.$store.dispatch("getUserInfo");
          //设置Vuex登录标志为true，默认userLogin为false
          // await this.$store.dispatch("setUser", true);
          this.$router.push('/Shop_SubmitApprovalList')
        } else {
          this.$message.error(res.Msg);


        }

      })

    },
    isValidPhoneNumber(phoneNumber) {
      return /^1[3-9]\d{9}$/.test(phoneNumber);
    },
    async verifyPhoneOfShopID() {
      await this.$http.post('/Shop_Manage/Shop_ApprovalUnit/GetLatestApprovalUnit', [
        {
          "Condition": "ShopId",
          "Keyword": this.ShopId
        },
        {
          "Condition": "ContactPhone",
          "Keyword": this.loginInfo.phone
        },
      ]).then(res => {
        if (res.Success) {
          if (res.Data.Id) {
            this.verify = true
            window.localStorage.setItem('ApprovalUnit', JSON.stringify(res.Data))
          } else {
            this.verify = false
          }
        } else {
          this.verify = false
        }
      }).catch(() => {
        this.verify = false
      })
    },
    async sendCode() {


      if (this.countdown > 0 || this.isSending) {
        return; // 防止重复点击获取
      }

      if (!this.isValidPhoneNumber(this.loginInfo.phone)) {

        this.$message.warning("请输入正确的手机号");

        return;
      }
      await this.verifyPhoneOfShopID()
      if (this.verify) {
        await this.getVerificationCode()
      } else {
        this.$message.warning("该手机号与当前商铺不符，请联系顾问单位")
        return
      }

      // 假设在这个方法中实现获取验证码的逻辑
      // 可以调用sendVerificationCode()方法获取验证码
      // 这里只是简单模拟获取过程
      // this.isSending = true;
      // this.startCountdown();

      // setTimeout(() => {
      //     // 假设获取成功后将isSending重置为false
      //     this.isSending = false;
      // }, 2000); // 这里使用2秒的延迟来模拟获取过程，你需要替换为实际的获取逻辑
    },
    getVerificationCode() {
      this.isSending = true;
      this.$http.post('/Base_Manage/Base_SMSConfig/GetTemplateCodeByCode?code=' + 'ZYT_ExternalUser_Login').then(codeData => {
        if (codeData.Success) {
          this.$http.post('/Base_ProjectManage/Project_ExternalUser/GetShortMessage?' + `Phone=${this.loginInfo.phone}&TemplateCode=` + codeData.Data,).then(res => {
            if (res.Success) {
              if (res.Data.IsSuccess) {
                this.$message.success(res.Data.Msg);
                this.startCountdown();
                this.isSending = false;
              } else {

                this.$message.error(res.Data.Msg);

                this.isSending = false;
              }
            } else {
              this.$message.error(res.Msg,);
              this.isSending = false;
            }
          })
        } else {
          this.isSending = false;
          this.$message.error(codeData.Msg);
        }
      })

    },

    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },


















  },

  created() {
    TokenCache.deleteToken();
    localStorage.clear()
    if (this.$route.query.shopId) {
      window.localStorage.setItem('ShopId', this.$route.query.shopId)
      this.ShopId = this.$route.query.shopId
    } else {
      this.ShopId = ''
    }

    //下载二维码

  },
  computed: {
    ...mapState(["userInfo", "isLogin"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  /* 设置背景色 */
  padding: 8px 0;
  /* 设置内边距 */
  font-size: 14px;
  /* 设置字体大小 */
  color: #666;
  /* 设置字体颜色 */
}

.dlbox {
  background-image: url("../../../assets/homebg1.jpg"),
    linear-gradient(to bottom right, #644ded, #811389) !important;
  background-size: 100% 95%;
  background-repeat: no-repeat;
}

.dlbox ::v-deep .el-tabs__item {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 18px !important;
  letter-spacing: 2px !important;
}

::v-deep .qrcodebox .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 35%;
  padding: 0 0 43px 0;
}

/* 登录注册 */
.box1 .tiaokuan {
  display: block;
  justify-content: center;
  align-items: center;
}

.el-input--prefix .el-input__inner {
  border-radius: 18px;
}

.mark .box1 .btn1[data-v-fae5bece] {
  font-size: 20px;
}

//loginbox
.loginAndRegister {
  width: 100%;
  // min-height: 63vh;
  margin: 0 auto;

  .box1,
  .box2 {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 30px;
  }

  .box1 .el-form-item {
    margin-top: 20px;
  }

  .box1 .btn1 {
    font-size: 20px;
  }

  .box1 .el-form-item .imgs1 {
    width: 37px;
    margin-top: 11px;
    margin-left: -55%;
  }

  .box1 .Back {
    margin-left: 75%;
    color: #d1100b;
    font-size: 20px;
    display: flex;
    margin-top: -14%;
    cursor: pointer;
  }

  .box1 .revise {
    margin-left: 79%;
    color: rgb(95, 170, 255);
    font-size: 20px;
    display: flex;
    margin-top: -11.5%;
    cursor: pointer;
  }
}

.liNum {
  list-style-type: disc;
  text-indent: 20px;
}
</style>
<style>
@keyframes banner {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60vw);
  }
}
</style>
